import { LabelType } from 'lib/types/label';
import styled from 'styled-components';
import theme from 'components/atomic-atoms/theme';

type StyledLabelType = {
  $backgroundColor?: string;
  type?: string;
  height?: string;
};

export const LabelsContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
`;

export const Styled = styled.div<StyledLabelType>`
  color: ${(props) => props.color || props.theme.colors.white()};
  background-color: ${(props) => props.$backgroundColor || props.theme.colors.emperor()};
  width: fit-content;
  height: ${(props) => (props.height ? props.height : undefined)};
  padding: 0 6px;
  margin: 4px;
  border-radius: 6px;
`;

export const Label = ({ type, text, height }: LabelType) => {
  const backgroundColor = () => {
    switch (type) {
      case 'primary':
        return theme.colors.emperor();
      case 'secondary':
        return theme.colors.darkOcean();
      case 'highlight':
        return theme.colors.meat();
      default:
        return theme.colors.emperor();
    }
  };

  const labelText = text.toUpperCase();

  return (
    <Styled $backgroundColor={backgroundColor()} height={height}>
      {labelText}
    </Styled>
  );
};
