import Link from 'next/link';
import React from 'react';
import styled from 'styled-components';

type ButtonProps = {
  margin?: string;
  size?: 'small' | 'normal' | 'large' | 'fit';
  width?: string;
  $secondary?: boolean;
  $alignSelf?: string;
  padding?: string;
  height?: string;
  withIcon?: boolean;
  navigation?: boolean;
  selected?: boolean;
  className?: string;
  children?: React.ReactNode;
  onClick?: () => void;
  type?: 'button' | 'submit' | 'reset';
};

export const StyledButton = styled.button.attrs((props) => ({
  className: props.className,
}))<ButtonProps>`
  display: ${(props) => (props.withIcon ? 'flex' : undefined)};
  flex-direction: ${(props) => (props.withIcon ? 'row' : undefined)};
  justify-content: ${(props) => (props.withIcon ? 'center' : undefined)};
  align-items: ${(props) => (props.withIcon ? 'center' : undefined)};
  margin: ${(props) => {
    if (props.margin) return props.margin;
    if (props.navigation) return '0px';
    return '0 5px';
  }};
  padding: ${(props) => {
    if (props.padding) return props.padding;
    if (props.size === 'fit') return '10px';
    return undefined;
  }};
  font-size: 16px;
  user-select: none;
  height: ${(props) => {
    if (props.height) return props.height;
    if (props.navigation) return '50px';
    if (!props.size) return '100%';
    if (props.size === 'small') return '30px';
    if (props.size === 'normal') return '40px';
    if (props.size === 'large') return '48px';
  }};
  max-width: ${(props) => {
    if (props.width) return props.width;
    if (props.navigation) return '150px';
    if (!props.size) return '100%';
    if (props.size === 'small') return '80px';
    if (props.size === 'normal') return '120px';
    if (props.size === 'large') return '140px';
  }};
  width: ${(props) => {
    if (props.width) return props.width;
    if (!props.size) return '100%';
    if (props.size === 'small') return '80px';
    if (props.size === 'normal') return '120px';
    if (props.size === 'large') return '140px';
  }};
  background-color: ${(props) => {
    if (props.navigation && props.selected) return props.theme.colors.summerSky();
    if (props.navigation) return props.theme.colors.ocean();
    if (props.$secondary) return props.theme.components.secondaryButtonBackgroundColor();
    return props.theme.components.primaryButtonBackgroundColor();
  }};
  color: ${(props) => {
    if (props.$secondary) return props.theme.components.secondaryButtonTextColor();
    return props.theme.components.primaryButtonTextColor();
  }};
  border: ${(props) => {
    if (props.navigation) return 'none';
    if (props.$secondary) return props.theme.components.secondaryButtonBorder();
    return 'none';
  }};
  outline: none;
  border-radius: ${(props) => {
    if (props.navigation) return 0;
    return '6px';
  }};
  align-self: ${(props) => (props.$alignSelf ? props.$alignSelf : undefined)};
  &:hover {
    background-color: ${(props) => {
      if (props.navigation) return props.theme.colors.deepOcean();
      if (props.$secondary) return props.theme.colors.gallery();
      return props.theme.colors.darkOrange();
    }};
  }
  &:active {
    background-color: ${(props) => {
      if (props.navigation) return props.theme.colors.summerSky();
      if (props.$secondary) return props.theme.colors.silver();
      return props.theme.colors.muleFawn();
    }};
  }
  &:focus-visible {
    outline: ${(props) => props.theme.components.buttonOutline()};
    outline-offset: 2px;
  }
  &:disabled {
    background-color: ${(props) => {
      if (props.$secondary) return props.theme.colors.white();
      return props.theme.colors.gallery();
    }};
    color: ${(props) => props.theme.colors.silver()};
    border: ${(props) => {
      if (props.$secondary) return props.theme.components.secondaryButtonBorderDisabled();
      return 'none';
    }};
  }
`;

export const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ margin, size, width, $secondary, $alignSelf, padding, height, withIcon, navigation, selected, children, onClick, type }: ButtonProps, ref) => {
    return (
      <StyledButton
        margin={margin}
        size={size}
        width={width}
        $secondary={$secondary}
        $alignSelf={$alignSelf}
        padding={padding}
        height={height}
        withIcon={withIcon}
        navigation={navigation}
        selected={selected}
        onClick={onClick}
        type={type}
        ref={ref}
      >
        {children}
      </StyledButton>
    );
  }
);

Button.displayName = 'Button';

type NextButtonType = ButtonProps & {
  href: string;
  children: React.ReactNode;
};

export const NextButton = ({
  href,
  children,
  margin,
  size,
  width,
  $secondary,
  $alignSelf,
  padding,
  height,
  withIcon,
  navigation,
  selected,
  className,
}: NextButtonType) => {
  return (
    <Link href={href} passHref legacyBehavior>
      <Button
        margin={margin}
        size={size}
        width={width}
        $secondary={$secondary}
        $alignSelf={$alignSelf}
        padding={padding}
        height={height}
        withIcon={withIcon}
        navigation={navigation}
        selected={selected}
        className={className}
      >
        {children}
      </Button>
    </Link>
  );
};
