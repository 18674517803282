export const getCookie = (name: string) => {
  if (typeof document === 'undefined') {
    return {
      cookieError: 'Unable to call getCookie() server-side.',
      cookieBooleanValue: false,
      cookieValue: '',
    };
  }

  if (!document.cookie.length) {
    return {
      cookieError: 'No cookies found.',
      cookieBooleanValue: false,
      cookieValue: '',
    };
  }

  const cookie = document.cookie.split(';').find((cookie) => cookie.includes(name));

  if (!cookie) {
    return {
      cookieError: `No cookie with name: ${name} found.`,
      cookieBooleanValue: false,
      cookieValue: '',
    };
  }

  const cookieValue = cookie.split('=')?.[1];

  return {
    cookieValue,
    cookieBooleanValue: !!cookieValue,
  };
};
