import styled from 'styled-components';

type ContainerProps = {
  alignItems?: string;
  $backgroundColor?: string;
  boxShadow?: string;
  border?: string;
  bottom?: number;
  className?: string;
  color?: string;
  display?: 'flex' | 'block' | 'none' | 'inline-block';
  $flexDirection?: 'row' | 'row-reverse' | 'column' | 'column-reverse';
  flexWrap?: 'wrap' | 'no-wrap' | 'wrap-reverse';
  height?: string;
  $justifyContent?: 'space-around' | 'space-between' | 'space-evenly' | 'flex-end' | 'flex-start' | 'center';
  left?: number;
  $margin?: string;
  padding?: string;
  position?: 'absolute' | 'relative' | 'fixed' | 'sticky';
  right?: number;
  textAlign?: string;
  top?: number;
  type?: 'page' | 'section' | 'content' | 'card';
  width?: string;
  zIndex?: number;
  $testID?: string;
  desktopOnly?: boolean;
};

const getTestID = (props: ContainerProps) => {
  if (props.$testID) return props.$testID;
  if (props.type) return props.type;
  return undefined;
};

export const Container = styled.div.attrs((props: ContainerProps) => ({
  className: props.className ? props.className : undefined,
  'data-testid': getTestID(props),
}))<ContainerProps>`
  box-shadow: ${(props) => {
    if (props.boxShadow) return props.boxShadow;
    return undefined;
  }};
  color: ${(props) => {
    if (props.color) return props.color;
    if (props.type === 'section') return props.theme.colors.black();
    return undefined;
  }};
  justify-content: ${(props) => {
    if (props.$justifyContent) return props.$justifyContent;
    if (props.type === 'section') return 'space-between';
    return undefined;
  }};
  display: ${(props) => {
    if (props.display) return props.display;
    if (props.desktopOnly) return 'none';
    return 'flex';
  }};
  flex-direction: ${(props) => {
    if (props.$flexDirection) return props.$flexDirection;
    if (props.type === 'card') return 'column';
    if (props.type === 'section') return 'row';
    return 'column';
  }};
  flex-wrap: ${(props) => (props.flexWrap ? props.flexWrap : undefined)};
  position: ${(props) => {
    if (props.className === 'cookie-information') return 'fixed';
    if (props.position) return props.position;
    if (props.type === 'page') return 'relative';
    return 'relative';
  }};
  top: ${(props) => (props.top ? `${props.top}px` : undefined)};
  z-index: ${(props) => {
    if (props.zIndex) return props.zIndex;
    if (props.className === 'cookie-information') return '2';
    return undefined;
  }};
  width: ${(props) => {
    if (props.width) return props.width;
    if (props.className === 'cookie-information') {
      return '100%';
    }
    if (props.type === 'card') return '300px';
    if (props.type === 'content') return '100%';
    if (props.type == 'page') return '100%';
    return undefined;
  }};
  height: ${(props) => {
    if (props.height) return props.height;
    if (props.type === 'page') return '100%';
    return undefined;
  }};
  background-color: ${(props) => {
    if (props.$backgroundColor) return props.$backgroundColor;
    if (props.type === 'card') return props.theme.colors.white();
    if (props.type === 'section') return props.theme.colors.white();
    return undefined;
  }};
  padding: ${(props) => {
    if (props.padding) return props.padding;
    if (props.type === 'section') return '10px';
    return undefined;
  }};
  border: ${(props) => {
    if (props.border) return props.border;
    if (props.type === 'card') return `2px solid ${props.theme.colors.nightrider()}`;
    return undefined;
  }};
  text-align: ${(props) => (props.textAlign ? props.textAlign : undefined)};
  align-items: ${(props) => (props.alignItems ? props.alignItems : undefined)};
  margin: ${(props) => {
    if (props.$margin) return props.$margin;
    if (props.type === 'section') return '0 18px 20px';
    if (props.type === 'page') return '55px 0 0';
    return undefined;
  }};

  @media (min-width: ${(props) => props.theme.breakpoints.desktop}) {
    background-color: ${(props) => {
      if (props.$backgroundColor) return props.$backgroundColor;
      return undefined;
    }};
    margin: ${(props) => {
      if (props.$margin) return props.$margin;
      if (props.type === 'section') return '0 10% 20px';
      return undefined;
    }};
    padding: ${(props) => {
      if (props.className === 'cookie-information') return '20px 25vw';
      if (props.className === 'feedback-content') return '0 80px 0 0';
      if (props.padding) return props.padding;
      if (props.type === 'page') return '0 0 40px 0';
      if (props.type === 'section') return '40px 20px';
      return undefined;
    }};
    height: ${(props) => {
      if (props.height) return props.height;
      if (props.type === 'page') return '100%';
      return undefined;
    }};
    justify-content: ${(props) => {
      if (props.$justifyContent) return props.$justifyContent;
      if (props.type === 'section') return 'space-between';
      return undefined;
    }};
    width: ${(props) => {
      if (props.className === 'cookie-information') return '100%';
      return undefined;
    }};
  }
`;
