import { CookiesConsent } from 'components/atomic-organisms/cookies-consent-modal';
import Head from 'next/head';
import React from 'react';
import { UserSessionType } from 'lib/store';
import dynamic from 'next/dynamic';
import { getCookie } from 'lib/enento';
import styled from 'styled-components';
import { useSession } from 'next-auth/react';

const NavBar = dynamic(() => import('components/atomic-organisms/navbar'));
const Footer = dynamic(() => import('components/atomic-organisms/footer'));

const MainWrapper = styled.div`
  position: absolute;
  min-height: 100%;
  min-width: 100%;
  display: flex;
  flex-direction: column;
  @media (min-width: ${(props) => props.theme.breakpoints.desktop}) {
    width: fit-content;
    height: 100%;
  }
`;

export default function Layout({ children }: { children: React.ReactNode }) {
  const { data: session, status } = useSession();
  const loading = status === 'loading';

  if (loading) {
    return null;
  }

  const { cookieBooleanValue: cookieDisclaimerShown } = getCookie('cookie-disclaimer-shown');
  const showCookieConsent = !cookieDisclaimerShown;

  const userSession = session?.user as UserSessionType;

  return (
    <MainWrapper>
      <Head>
        <title>Enento Developer Portal</title>
      </Head>
      <NavBar userSession={userSession} />
      {showCookieConsent ? <CookiesConsent /> : null}
      {children}
      <Footer />
    </MainWrapper>
  );
}
