import { Button, NextButton } from 'components/atomic-atoms/Button';

import { Container } from 'components/atomic-atoms/Container';
import { Label } from 'components/atomic-atoms/Label';
import { LabelType } from 'lib/types/label';
import React from 'react';
import styled from 'styled-components';
import theme from 'components/atomic-atoms/theme';

type StyleTypes = React.CSSProperties;

type CardHeaderProps = {
  headerSize?: string;
  $margin?: string;
};

const CardHeader = styled.h3<CardHeaderProps>`
  font-size: ${(props) => (props.headerSize ? props.headerSize : '24px')};
  margin: ${(props) => (props.$margin ? props.$margin : undefined)};
`;

type CardTextProps = {
  margin?: string;
  fontSize?: string;
};

const CardText = styled.p<CardTextProps>`
  margin: ${(props) => (props.margin ? props.margin : '0 0 40px')};
  font-size: ${(props) => (props.fontSize ? props.fontSize : '16px')};
`;

type ButtonWrapperProps = {
  $buttonAlign?: string;
};

const ButtonWrapper = styled.div<ButtonWrapperProps>`
  align-self: ${(props) => {
    if (props.$buttonAlign) return props.$buttonAlign;
    return 'flex-end';
  }};
`;

type CardProps = {
  backgroundColor?: string;
  buttonAlign?: string;
  buttonCallback?: () => void;
  buttonText?: string;
  className?: string;
  filter?: string;
  header: string;
  headerSize?: string;
  labels?: LabelType[];
  style?: StyleTypes;
  text?: string;
  type?: 'api-product';
  width?: string;
  margin?: string;
  padding?: string;
  contentID?: string;
  $testID?: string;
};

const CardButton = ({ buttonText, buttonCallback, buttonAlign }: { buttonText: string; buttonCallback: () => void; buttonAlign?: string }) => {
  return (
    <ButtonWrapper $buttonAlign={buttonAlign}>
      <Button size="normal" onClick={() => buttonCallback()}>
        {buttonText}
      </Button>
    </ButtonWrapper>
  );
};

const ApiProductLabels = ({ labels }: { labels: LabelType[] | undefined }) => {
  if (!labels) {
    return null;
  }

  const content: React.ReactNode[] = [];

  labels.map((label, index) => content.push(<Label type={label.type} text={label.text} key={`${label.text}-${index}`} height="fit-content" />));

  return content;
};

export const Card = ({
  backgroundColor,
  buttonAlign,
  buttonCallback,
  buttonText,
  className,
  filter = 'all',
  header,
  headerSize,
  labels,
  margin,
  text,
  type,
  width,
  padding,
  contentID,
  $testID,
}: CardProps) => {
  if (type === 'api-product') {
    if (labels?.some((label) => label.text.includes(filter)) || filter === 'all') {
      const productHref = `/user/api-product/${contentID ?? ''}`;
      return (
        <Container type="card" padding="10px" $margin="40px" $justifyContent="space-between" className={className} $testID={$testID}>
          <Container height="40px" $justifyContent="flex-end" $flexDirection="row">
            {ApiProductLabels({ labels })}
          </Container>
          <Container type="content" $backgroundColor={theme.colors.beach()} $flexDirection="column" padding="10px">
            <CardHeader headerSize={headerSize ?? '16px'} $margin="0px">
              {header ? header : 'Card header'}
            </CardHeader>
            <CardText fontSize="12px" margin="0px">
              {text ? text : ''}
            </CardText>
            <NextButton size="small" $alignSelf="flex-end" href={productHref}>
              Manage
            </NextButton>
          </Container>
        </Container>
      );
    }
    return null;
  }

  return (
    <Container
      type="content"
      width={width}
      $margin={margin}
      $backgroundColor={backgroundColor ? backgroundColor : theme.colors.white()}
      padding={padding ? padding : '20px'}
      className={className}
      $testID={$testID}
    >
      <CardHeader headerSize={headerSize} $margin="0px">
        {header ? header : 'Card header'}
      </CardHeader>
      <CardText fontSize="12px" margin="0px">
        {text ? text : ''}
      </CardText>
      {buttonText && buttonCallback ? <CardButton buttonAlign={buttonAlign} buttonText={buttonText} buttonCallback={buttonCallback} /> : null}
    </Container>
  );
};
