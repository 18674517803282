import { Card } from 'components/atomic-molecules/card';
import { useState } from 'react';

export const CookiesConsent = () => {
  const [showCard, setShowCard] = useState(true);

  const cookieOnClick = () => {
    document.cookie = 'cookie-disclaimer-shown=true';
    setShowCard(false);
  };

  if (!showCard) {
    return null;
  }

  const fallbackData = {
    header: 'This website uses cookies',
    text: 'Only necessary cookies are being used on this website. Necessary cookies are those that allow you to use a website by enabling basic functionality such as page navigation and secure access to parts of the website. The website cannot work properly without these cookies.',
    buttonText: 'OK',
  };

  return (
    <>
      {showCard ? (
        <>
          <Card
            header={fallbackData.header}
            text={fallbackData.text}
            buttonText={fallbackData.buttonText}
            buttonCallback={cookieOnClick}
            buttonAlign="flex-start"
            margin="55px auto 0 auto"
            className="cookie-information"
          />
        </>
      ) : null}
    </>
  );
};
